import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import ThemeToggleButton from '../Context/ThemeToggleButton';

const Header: React.FC = () => {
  return (
    <header className="header">
      <Link to="/">Главная</Link>
      <Link to="/forum">Форум</Link>
      <Link to="/search">Поиск</Link>
      <Link to="/bookmarks">Закладки</Link>
      <Link to="/register">Регистрация</Link>
      <ThemeToggleButton />
    </header>
  );
};

export default Header;