import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { Button, Pagination, Select, MenuItem } from "@mui/material";
import './Modalplayer.scss';

Modal.setAppElement('#root');

interface DoramaModalProps {
  id: number;
  isOpen: boolean;
  onRequestClose: () => void;
}

const DoramaModal: React.FC<DoramaModalProps> = ({ id, isOpen, onRequestClose }) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [episodes, setEpisodes] = useState<{ episodeNumber: string; languageseries: string; urldorama: string }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEpisode, setSelectedEpisode] = useState<string | null>(null);
  const episodesPerPage = 1;

  useEffect(() => {
    axios.get(`https://api.ulamag.by/api/v1/doramaepisod/${id}/`)
      .then((response) => {
        const allEpisodes = Object.entries(response.data.episodes).flatMap(([episodeNumber, episodeData]) =>
          (episodeData as { languageseries: string; urldorama: string }[]).map((episode) => ({
            episodeNumber,
            ...episode
          }))
        );
        setEpisodes(allEpisodes);

        const lastWatchedEpisode = localStorage.getItem(`lastWatchedEpisode-${id}`);
        const lastPage = localStorage.getItem(`lastPage-${id}`);
        
        if (lastPage) {
          setCurrentPage(parseInt(lastPage, 10));
        }

        if (lastWatchedEpisode) {
          const episode = allEpisodes.find(ep => ep.episodeNumber === lastWatchedEpisode);
          if (episode) {
            setSelectedEpisode(episode.episodeNumber);
            setVideoUrl(episode.urldorama);
          }
        } else if (allEpisodes.length > 0) {
          setSelectedEpisode(allEpisodes[0].episodeNumber);
          setVideoUrl(allEpisodes[0].urldorama);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  const handleEpisodeChange = (url: string, episodeNumber: string) => {
    setVideoUrl(url);
    setSelectedEpisode(episodeNumber);
    localStorage.setItem(`lastWatchedEpisode-${id}`, episodeNumber);
  };

  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    const episodeNumber = Array.from(new Set(episodes.map(episode => episode.episodeNumber)))[page - 1];
    setSelectedEpisode(episodeNumber);
    const firstEpisode = episodes.find(episode => episode.episodeNumber === episodeNumber);
    if (firstEpisode) {
      setVideoUrl(firstEpisode.urldorama);
      localStorage.setItem(`lastWatchedEpisode-${id}`, episodeNumber);
    }
    localStorage.setItem(`lastPage-${id}`, page.toString());
  };

  const currentEpisodeOptions = episodes.filter(episode => episode.episodeNumber === selectedEpisode);

  return (
    <>
      <div className="block-modal">
        <Modal 
          isOpen={isOpen} 
          onRequestClose={onRequestClose} 
          contentLabel="Dorama Modal"
          className="dorama-modal"
          overlayClassName="dorama-modal-overlay"
        >
          <div className="modal-content">
            <div className="video-player">
              {videoUrl && <iframe src={videoUrl} width="853" height="480" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe>}
            </div>
            <div className="controls">
              <Select sx={{background:'black', color:'white'}} value={videoUrl} onChange={(e) => handleEpisodeChange(e.target.value as string, selectedEpisode as string)} displayEmpty>
                <MenuItem className="MenuItem" value="" disabled>Выберите озвучку</MenuItem>
                {currentEpisodeOptions.map((episode, index) => (
                  <MenuItem key={index} value={episode.urldorama}>
                    {episode.languageseries}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Pagination
              className="pagination"
              count={Math.max(10, Math.ceil(Array.from(new Set(episodes.map(episode => episode.episodeNumber))).length / episodesPerPage))}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
            />
            <Button className="btn-modal" variant="contained" color="secondary" onClick={onRequestClose} sx={{background:'black'}}>Закрыть</Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DoramaModal;