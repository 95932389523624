import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Home.scss';
import DoramaModal from '../modalplayer/DoramaModal';
import CardInfo from '../CardInfo/CardInfo';


interface Drama {
  id: number;
  name: string;
  img: string;
}

const Home: React.FC = () => {
  const [dramas, setDramas] = useState<Drama[]>([]);
  const [selectedDramaId, setSelectedDramaId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axios.get<Drama[]>('https://api.ulamag.by/api/v1/doramaslider/')
      .then((response) => {
        setDramas(response.data.slice(0, 15));
      });
  }, []);

  const handleDramaClick = (id: number) => {
    setSelectedDramaId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDramaId(null);
  };

  return (
    <div className="slider">
      <Swiper
        spaceBetween={20}
        slidesPerView={7}
        breakpoints={{
          768: {
            slidesPerView: 7,
          },
          480: {
            slidesPerView: 1,
          },
        }}
      >
        {dramas.map((drama) => (
          <SwiperSlide key={drama.id}>
            <div
              className="drama-card"
              onClick={() => handleDramaClick(drama.id)}
            >
              <img src={drama.img} alt={drama.name} />
              <h2>{drama.name}</h2>
            </div>
          </SwiperSlide>
        ))}
        <CardInfo/>
      </Swiper>
      {isModalOpen && selectedDramaId !== null && (
        <DoramaModal
          id={selectedDramaId}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
        />
      )}
    </div>
  );
};

export default Home;


