import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography } from '@mui/material';
import './RegistrationForm.module.scss';

const RegisterForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await axios.post('https://api.ulamag.by/api/register/', {
        username,
        email, 
        password,
      }, {
        headers: {
          'Content-Type': 'application/json', // Установка заголовка Content-Type
        },
      });

      // Обработка успешного ответа
      setSuccess('Регистрация успешна!');
      console.log('Registration successful:', response.data);
    } catch (err) {
      // Обработка ошибок
      console.error('Registration error:', err);
      setError('Ошибка регистрации. Попробуйте еще раз.');
    }
  };

  return (
    <div className="register-form">
      <Typography variant="h4">Регистрация</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Имя пользователя"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          type="email" // Установка типа email для валидации
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Пароль"
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="primary">{success}</Typography>}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Зарегистрироваться
        </Button>
      </form>
    </div>
  );
};

export default RegisterForm;