import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Импортируйте useParams и useNavigate
import axios from 'axios';
import './CardInfo.scss';
import DoramaModal from '../modalplayer/DoramaModal';
import Pagination from '@mui/material/Pagination';
import { Fab, Tooltip } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

interface Drama {
  id: number;
  name: {
    rus: string;
  };
  img: string;
}

const CardInfo: React.FC = () => {
  const { page } = useParams<{ page: string }>(); // Получите номер страницы из URL
  const navigate = useNavigate(); // Для навигации
  const [dramas, setDramas] = useState<Drama[]>([]);
  const [selectedDramaId, setSelectedDramaId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page || '1', 10)); // Установите начальную страницу
  const [totalPages, setTotalPages] = useState(7);

  const fetchDramas = async (page: number) => {
    try {
      const response = await axios.get<{
        results: Drama[];
        total_pages: number;
      }>(`https://api.ulamag.by/api/v1/doramalist/page/?page=${page}`);
      setDramas(response.data.results);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error('Error fetching dramas:', error);
    }
  };

  useEffect(() => {
    fetchDramas(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (page) {
      setCurrentPage(parseInt(page, 10));
    }
  }, [page]);

  const handleDramaClick = (id: number) => {
    setSelectedDramaId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDramaId(null);
  };

  const handlePageChange = (page: number): void => {
    setCurrentPage(page);
    navigate(`/page/${page}`); // Измените URL при изменении страницы
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="wrapper">
      <div className="card-container">
        {dramas.map((drama) => (
          <div
            key={drama.id}
            className="drama-card1"
            onClick={() => handleDramaClick(drama.id)}
          >
            <div className="drama-card-content">
              <div className="drama-card-image-container">
                <img src={drama.img} alt={drama.name.rus} />
                <Tooltip
                  sx={{ color: 'white', background: 'black', opacity: '0.9' }}
                  title={drama.name.rus}
                  placement="top-end"
                  arrow
                  className="drama-card-tooltip"
                >
                  <Fab color="primary" size="small" aria-label="info" className="drama-card-info-button">
                    !
                  </Fab>
                </Tooltip>
              </div>
              <h2>{drama.name.rus}</h2>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        className='pagination1'
        count={totalPages}
        page={currentPage}
        onChange={(_, page) => handlePageChange(page)} 
      />
      {isModalOpen && selectedDramaId !== null && (
        <DoramaModal
          id={selectedDramaId}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
        />
      )}
      <Fab
        sx={{ color: 'white', background: 'black', opacity: '0.9' }}
        color="primary"
        aria-label="scroll to top"
        onClick={scrollToTop}
        style={{ position: 'fixed', bottom: 12, right: 12 }}
      >
        <ArrowUpward />
      </Fab>
    </div>
  );
};

export default CardInfo;