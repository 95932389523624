import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeProvider } from './component/Context/ThemeContext';
import Header from './component/Header/Header';
import Home from './component/Home/Top';
import CardInfo from './component/CardInfo/CardInfo'; // Импортируйте CardInfo
import Forum from './component/Forum/Forum';
import Search from './component/Search/Search';
import RegisterForm from './component/RegisterForm/RegisterForm';
import LoginForm from './component/RegisterForm/LoginForm';
import Bookmarks from './component/Bookmarks/Bookmarks';

const muiTheme = createTheme();

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/forum" element={<Forum />} />
            <Route path="/search" element={<Search />} />
            <Route path="/bookmarks" element={<Bookmarks />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/page/:page" element={<CardInfo />} /> {/* Добавлен маршрут для пагинации */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;