import React, { useState } from 'react';
import axios from 'axios';
import styles from './Search.module.scss';
import DoramaModal from '../modalplayer/DoramaModal';

interface Series {
    id: number;
    name: {
      rus: string;
    };
    img: string;
  }
  
  const Search: React.FC = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<Series[]>([]);
    const [selectedSeriesId, setSelectedSeriesId] = useState<number | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      if (e.target.value.length > 2) {
        try {
          const response = await axios.get(`https://api.ulamag.by/api/v1/questions/?search=${e.target.value}`);
          setResults(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        setResults([]);
      }
    };
  
    const handleSeriesClick = (id: number) => {
      setSelectedSeriesId(id);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      setSelectedSeriesId(null);
    };
  
    return (
      <div className={styles['search-container']}>
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          placeholder="Поиск сериалов..."
          className={styles['search-input']} 
        />
        <div className={styles['search-results']}>
          {results.map((series) => (
            <div key={series.id} className={styles['search-result-card']} onClick={() => handleSeriesClick(series.id)}>
              <img src={series.img} alt={series.name.rus} />
              <p>{series.name.rus}</p>
            </div>
          ))}
        </div>
        {isModalOpen && selectedSeriesId !== null && (
          <DoramaModal
            id={selectedSeriesId}
            isOpen={isModalOpen}
            onRequestClose={closeModal}
          />
        )}
      </div>
    );
  };
  
  export default Search;